import * as React from "react"
import { Helmet } from "react-helmet"
import { Footer } from "../../components/footer"
import Header2 from "../../components/header2"
import { Mappa2 } from "../../components/mappa2"


function Render() {
	return <div style={{
		display: "flex",
		flexDirection: "column",
		height: "100vh"
	}}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Antonio Gallenga</title>
			{/* <link rel="canonical" href="http://mysite.com/example" /> */}
		</Helmet>
		<Header2 lang="en" />

		<Mappa2 lang="en" />
		<Footer lang="en" floating={true} />
	</div>
}


export default Render