import * as React from "react"
import * as styles from './mappa2.module.scss'
import { Lang, punti } from "./pagine";
import { Stemma } from "./stemma";


type Props = {
	lang: Lang
}

export class Mappa2 extends React.Component<Props, {
	isScrolling: boolean
	scrollingStarted?: Date
	clientX?: number
	scrollLeft?: number
	scrollTop?: number
	clientY?: number,
	luogoSelezionato?: string[]
}> {
	myRef: React.RefObject<HTMLDivElement>

	constructor(props: Props) {
		super(props);
		this.myRef = React.createRef();
		this.state = { isScrolling: false }
	}

	componentDidMount() {
		const el = this.myRef.current!;
		if (el.clientWidth < 780 * 2)
			el.scrollLeft = 780 - el.clientWidth / 2
		if (el.clientHeight < 1220 * 2)
			el.scrollTop = 1220 - el.clientHeight / 2
	}

	imgMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.preventDefault()
		e.stopPropagation()
		const { scrollLeft, scrollTop } = this.myRef.current!;
		this.setState({ isScrolling: true, scrollLeft, scrollTop, clientX: e.clientX, clientY: e.clientY, scrollingStarted: new Date() });
	}
	imgMouseUp(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		// console.log('imgMouseUp')
		e.preventDefault()
		e.stopPropagation()
		this.setState({ isScrolling: false })
	}
	imgMouseMove(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		if (!this.state.isScrolling)
			return
		// this.myRef.current.scrollLeft = this.state.scrollLeft - (e.clientX - this.state.clientX);
		// this.myRef.current.scrollTop = this.state.scrollTop - (e.clientY - this.state.clientY);
		this.myRef.current!.scrollTo(
			this.state.scrollLeft! - (e.clientX - this.state.clientX!),
			this.state.scrollTop! - (e.clientY - this.state.clientY!)
		)
	}
	imgMouseLeave(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		// console.log('imgMouseLeave')
		this.setState({ isScrolling: false })
	}


	isClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		const passati = this.state.scrollingStarted && (new Date().getTime() - this.state.scrollingStarted.getTime())
		const dx = e.clientX - this.state.clientX!
		const dy = e.clientY - this.state.clientY!

		if (dx == 0 && dy == 0) {
			// console.log('mosso niente')
			return true
		}

		if (Math.max(Math.abs(dx), Math.max(dy)) > 10) {
			// console.log('mosso troppo')
			return false
		}

		if (passati! < 400) {
			// console.log('passato poco')
			return true
		}

		return false
	}

	areaClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		if (!this.isClick(e)) {
			e.preventDefault()
			e.stopPropagation()
		}
		// else {
		// 	console.log('fermo per debug')
		// 	e.preventDefault()
		// 	e.stopPropagation()
		// }
	}



	render() {
		function areaOnMouse(that: Mappa2) {
			return {
				onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
					that.imgMouseDown(e)
				},
				onMouseUp(e: React.MouseEvent<HTMLElement, MouseEvent>) {
					that.imgMouseUp(e)
				},
				onMouseMove(e: React.MouseEvent<HTMLElement, MouseEvent>) {
					that.imgMouseMove(e)
				},
				onClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
					that.areaClick(e)
				},
				// onMouseEnter(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
				// 	console.log('enter', (e.target as any).href)
				// },
				// onMouseLeave(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
				// 	console.log('leave', (e.target as any).href)
				// }
			}
		}

		return <>
			<div
				ref={this.myRef}
				style={{
					// width: "100vw",
					// height: "100vh",
					overflow: "auto",
					position: "relative"
				}}
				onMouseLeave={e => this.imgMouseLeave(e)}
			>
				<picture>
					<source type="image/webp" srcSet="mappa.webp" />
					<source type="image/jpeg" srcSet="mappa.jpg" />
					<img
						alt="mappa"
						width="2224"
						height="1668"
						src="mappa.jpg"
						onMouseDown={e => this.imgMouseDown(e)}
						onMouseUp={e => this.imgMouseUp(e)}
						onMouseMove={e => this.imgMouseMove(e)}
					/>
				</picture>
				{punti.map(p =>
					<button
						key={p.id}
						className={styles.pallino}
						style={{
							left: p.x + "px",
							top: p.y + "px",
							backgroundColor: p.dbgbg
						}}
						{...areaOnMouse(this)}
						onClick={() => this.setState({
							luogoSelezionato: p.pagine
						})}
					/>

					// <Link key={p.id} className={styles.pallino} to={"p/" + p.id} {...areaOnMouse(this)} style={{
					// 	left: p.x + "px",
					// 	top: p.y + "px",
					// }} />


				)}
			</div >
			{this.state.luogoSelezionato && <button
				className={styles.anteprimaLuogo}
				onClick={() => this.setState({ luogoSelezionato: undefined })}
			>
				<Stemma lang={this.props.lang} luoghi={this.state.luogoSelezionato} />
			</button>
			}
		</>
	}
}


