import { Link } from "gatsby"
import * as React from "react"
import { ImagoPicture } from "./ImagoPic"
import { Lang, luoghi } from "./pagine"
import * as styles from './stemma.module.scss'




function clazz(luoghiLength: number) {
	if (luoghiLength == 3)
		return styles.stemma3
	if (luoghiLength == 2)
		return styles.stemma2
	return styles.stemma
}

export function Stemma(props: {
	lang: Lang
	luoghi: string[]
}) {
	// const pa = props.pagine[0]
	// const p = paginaNomeUrl(props.lang, pa)

	// return <Link className={styles.stemma} to={p.url} title={p.nome}><img alt={p.nome} src={`stemmi/${props.lang}/${pa}.PNG`} /></Link>

	return <>
		{props.luoghi.map(p => {
			const lu = luoghi[p]
			const la = lu[props.lang]
			return <Link key={lu.id} className={clazz(props.luoghi.length)} to={la.url} title={la.nome}><ImagoPicture alt={la.nome} name={`stemmi/${props.lang}/${lu.id}.PNG`} /></Link>
		})}
	</>
}